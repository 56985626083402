/* eslint-disable @scandipwa/scandipwa-guidelines/file-structure */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { lazy, Suspense } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import {
    HomeSlider, NewProducts, ProductListWidget, RecentlyViewedWidget, WidgetFactoryComponent as SourceWidgetFactory,
} from 'SourceComponent/WidgetFactory/WidgetFactory.component';

import {
    Widget,
} from './WidgetFactory.config';

import './WidgetFactory.override.style';

export const ItemReturnForm = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "itemReturn" */ 'Component/ItemReturnForm'));
export const StoreReviews = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "reviews" */ 'Component/StoreReviews'));
export const ProductOfTheWeek = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "productOfTheWeek" */ 'Component/ProductOfTheWeek'));

/** @namespace Satisfly/Component/WidgetFactory/Component */
export class WidgetFactoryComponent extends SourceWidgetFactory {
    reactComponents = {
        StoreReviews,
        ItemReturnForm,
        ProductOfTheWeek,
    };

    renderMap = {
        [Widget.SLIDER]: {
            component: HomeSlider,
            fallback: this.renderSliderFallback,
        },
        [Widget.NEW_PRODUCTS]: {
            component: NewProducts,
        },
        [Widget.CATALOG_PRODUCT_LIST]: {
            component: ProductListWidget,
            fallback: this.renderProductListFallback,
        },
        [Widget.RECENTLY_VIEWED]: {
            component: RecentlyViewedWidget,
        },
        [Widget.REACT_COMPONENT]: {
            // eslint-disable-next-line react/destructuring-assignment
            component: this.reactComponents[this.props.componentName],
        },
    };

    renderContent() {
        const {
            type,
            sliderId = 0,
            displayType,
            productsCount,
            showPager,
            storeId,
            title,
            conditionsEncoded,
            onLoad,
            productsPerSlide,
        } = this.props;
        const {
            component: Widget,
            fallback = () => <div />,
        } = this.renderMap[type] || {};

        if (Widget !== undefined) {
            return (
                <Suspense fallback={ fallback() }>
                    <RenderWhenVisible>
                        <Widget
                          sliderId={ sliderId }
                          displayType={ displayType }
                          productsCount={ productsCount }
                          showPager={ !!showPager }
                          storeId={ storeId }
                          title={ title }
                          conditionsEncoded={ conditionsEncoded }
                          onLoad={ onLoad }
                          productsPerSlide={ productsPerSlide }
                        />
                    </RenderWhenVisible>
                </Suspense>
            );
        }

        return null;
    }

    renderProductListFallback() {
        return (
            <div block="WidgetFactory" elem="ProductListPlaceholder" />
        );
    }
}

export default WidgetFactoryComponent;
